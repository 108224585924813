<template>
  <v-footer
    id="home-footer"
    dark
    style="background-color: #234e79; background-image: linear-gradient(110deg, #234e79 0%, #9895e2 100%);"
    min-height="72"
  >
    <v-container>
      <v-row
        justify="start"
        no-gutters
      >
        <v-col
          :cols="$vuetify.breakpoint.mobile ? 12 : 2"
        >
          <img
            :src="require('@/assets/Lavvira_logo_SVG.svg')"
            class="mt-3"
            style="width: 150px; cursor: pointer;"
            @click="$router.push('/')"
          >
          <v-divider
            v-if="$vuetify.breakpoint.mobile"
            class="mb-0"
          />
        </v-col>

        <v-col
          :cols="$vuetify.breakpoint.mobile ? 12 : 6"
        >
          <h3
            class="secondary--text mt-3"
          >
            Legal
          </h3>
          <a
            href="/terms-of-use"
            target="_blank"
            style="color: white;"
            class="mt-2"
          >
            Terms of Use
          </a>
          <v-divider
            v-if="$vuetify.breakpoint.mobile"
            class="mt-2"
          />
        </v-col>

        <v-col
          :cols="$vuetify.breakpoint.mobile ? 12 : 3"
        >
          <h3
            class="secondary--text mt-3"
          >
            Contact
          </h3>
          <base-business-info
            class="ml-n4"
          />
          <v-divider
            v-if="$vuetify.breakpoint.mobile"
            class="mb-4 mt-n2"
          />
        </v-col>

        <v-col
          cols="12"
          class="text-center"
        >
          <div>
            <a
              href="https://twitter.com/"
              target="_blank"
            >
              <img
                :src="require('@/assets/Twitter-X-White-Logo-PNG.png')"
                style="width: 18px; cursor: pointer; color: white;"
                class="mb-n1"
              >
            </a>
            <template v-for="(s, index) in social">
              <v-icon
                :key="index"
                class="white--text pa-1 pa-md-0 mx-2"
                href="#"
              >
                {{ s }}
              </v-icon>
            </template>
          </div>
        </v-col>

        <v-col
          cols="12"
          class="text-center"
        >
          <span
            style="font-size: 16px;"
          >
            Copyright &copy; 2024 LAVVIRA
          </span>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
  export default {
    name: 'HomeFooter',

    data: () => ({
      social: [
        // 'mdi-twitter',
        'mdi-linkedin',
      ],
    }),
  }
</script>

<style lang="sass">
  #home-footer a
    text-decoration: none
</style>
